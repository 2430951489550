module.exports = {

    development: {
        // local
        backend_url: 'http://localhost:4000/',
        frontend_url: 'http://localhost:8080',
        // production
        //backend_url: 'https://videoapi.daily-press.info/',
        //frontend_url: 'https://videoapp.daily-press.info',
        rows_per_page: 25,
        gui: {
            color     : '#4db6ac',
            icon      : 'default.png',
            app_label : 'Daily Press',
        },
    },

    production: {
        // production
        backend_url: 'https://videoapi.daily-press.info/',
        frontend_url: 'https://videoapp.daily-press.info',
        // development
        //backend_url: 'https://dpvideoapi.digitalautomations.it/',
        //frontend_url: 'https://dpvideoapp.digitalautomations.it',
        rows_per_page: 25,
        gui: {
            color     : '#534dca', // production
            //color     : '#4db6ac', // development
            icon      : 'default.png',
            app_label : 'Daily Press',
        },
    },

};




