module.exports = {

    replace_pdf: {
        file : { label: 'Nuovo pdf' },
    },

    orazio: {
        settings      : { size: 'sm', },
        rassegna      : { label: 'Rassegna' },
        url           : { label: 'Url' },
        tipo_rassegna : { label: 'Tipo rassegna', readonly: true },
        file          : { label: 'File', readonly: true },
        testata       : { label: 'Testata', readonly: true },
        sezione       : { label: 'Sezione' },
        data_notizia  : { label: 'Data notizia' },
        data_rassegna : { label: 'Data rassegna' },
        titolo        : { label: 'Titolo' },
    },

    site: {
        settings    : { size: 'sm', },
        label       : { label: 'Nome' },
        active      : { label: 'Attivo' },
        customer_id : { label: 'Customer' },
        created     : { skip: true },
        config      : { skip: true },
        id          : { skip: true },
    },

    channel: {
        settings     : { size: 'sm', },
        label        : { label: 'Nome' },
        active       : { label: 'Attivo' },
        customer_id  : { label: 'Customer', skip: true },
        channel_id   : { label: 'ID del canale', helper: 'Modificare con attenzione' },
        must_contain : { label: 'Filtro sul titolo del video', helper: 'Maiuscole o minuscole non influenzano il filtro', min_length: 1 },
        created      : { skip: true },
        config       : { skip: true },
        id           : { skip: true },
    },

    extraction: {
        settings    : { size: 'sm', },
        file        : { label: 'Video', },
        file_name   : { skip: true },
        report      : { skip: true },
        customer_id : { skip: true },
        hash        : { skip: true, },
        user_id     : { skip: true, },
        txtpath     : { skip: true, },
        audiopath   : { skip: true, },
        videopath   : { skip: true, },
        msg         : { skip: true, },
        status      : { skip: true, },
        created     : { skip: true, },
		deleted     : { skip: true, },
        id          : { skip: true, },
    },

    customer: {
        settings  : { size: 'sm', },
        label     : { label: 'Nome', },
        active    : { label: 'Attivo' },
        created   : { skip: true, },
        config    : { skip: true, },
        id        : { skip: true, },
    },

    company: {
        settings    : { size: 'sm', },
        label       : { label: 'Nome', },
        active      : { label: 'Attivo' },
        created     : { skip: true, },
        config      : { skip: true, },
        customer_id : { skip: true, },
        created_by  : { skip: true, },
        id          : { skip: true, },
    },

    add_youtube_link: {
        settings    : { size: 'sm', },
        title       : { label: 'Titolo', },
        link        : { label: 'Link', },
    },

    rwcompany: {
        settings    : { size: 'sm', },
        label       : { label: 'Nome', },
        active      : { label: 'Attivo' },
        sezione     : { label: 'Sezione' },
        rassegna    : { label: 'Rassegna', helper: 'Questo campo deve essere identico all\'etichetta azzurra del campo "Rassegna" di datastampa' },
        date_shift  : { label: 'Correzione data', helper: 'La data della rassegna stampa sarà spostata di un equivalente numero di giorni' },
        created     : { skip: true, },
        config      : { skip: true, },
        customer_id : { skip: true, },
        created_by  : { skip: true, },
        id          : { skip: true, },
    },

    keyword: {
        settings         : { size: 'sm', },
        label            : { label: 'Nome', },
        company_id       : { label: 'Cliente' },
        enabled          : { label: 'Attivo' },
        case_insensitive : { label: 'Case insensitive' },
        customer_id      : { label: 'Customer', skip: true },
        created          : { skip: true, },
        created_by       : { skip: true, },
        config           : { skip: true, },
        id               : { skip: true, },
    },

    rwkeyword: {
        settings         : { size: 'sm', },
        label            : { label: 'Nome', },
        rwcompany_id     : { label: 'Cliente' },
        enabled          : { label: 'Attivo' },
        case_insensitive : { label: 'Case insensitive' },
        exact_search     : { label: 'Ricerca esatta' },
        gsearch          : { label: 'Google standard' },
        gnews            : { label: 'Google News' },
        customer_id      : { label: 'Customer', skip: true },
        deleted          : { skip: true, },
        created          : { skip: true, },
        created_by       : { skip: true, },
        config           : { skip: true, },
        id               : { skip: true, },
    },

    search_keyword: {
        settings         : { size: 'sm', },
        label            : { label: 'Nome', helper: 'il testo verrà cercato all\'interno della etichetta, senza distinzioni tra maiuscole e minuscole' },
        company_id       : { label: 'Cliente' },
    },

    search_rwkeyword: {
        settings         : { size: 'sm', },
        label            : { label: 'Nome', helper: 'il testo verrà cercato all\'interno della etichetta, senza distinzioni tra maiuscole e minuscole' },
        rwcompany_id     : { label: 'Cliente' },
    },

    rassegna_manuale: {
        settings         : { size: 'sm', },
        links            : { label: 'Lista di url', helper: 'inserire un url per ogni riga', },
        rwcompany_id     : { label: 'Cliente' },
    },

}
