<script>
    export default {
        name: 'ank-controller',
        props: [ 'anchors' ],
        methods: {
            go_to_prev() {
                if ( this.actual_ank === null || this.actual_ank === 0 ) {
                    this.actual_ank = this.anchors.size;
                }
                this.actual_ank -= 1;
                this.go_to_anchor();
            },
            go_to_next() {
                if ( this.actual_ank === null || this.actual_ank === this.anchors.size - 1 ) {
                    this.actual_ank = -1;
                }
                this.actual_ank += 1;
                this.go_to_anchor();
            },
            go_to_anchor() {
                this.$emit( 'go-to-anchor', this.actual_ank )
            },
        },
        data() {
            return {
                actual_ank: null
            };
        },
    }
</script>

<template>
    <div>
        <!-- anchors controller -->
        <div class="ank ank-top" >
            <b-button size="sm" variant="primary" @click="go_to_prev" pill>
                <icon name="arrow-up"/>
            </b-button>
        </div>
        <div class="ank ank-bottom" >
            <b-button size="sm" variant="primary" @click="go_to_next" pill>
                <icon name="arrow-down"/>
            </b-button>
        </div>
    </div>
</template>

<style>
    .ank {
        position: fixed;
        left: 14px;
        padding: 5px;
        z-index: 1000;
    }
    .ank-top {
        top: 80px;
    }
    .ank-bottom {
        top: 120px;
    }
</style>

