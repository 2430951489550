////////////////////

import base_module from '@/vue-model-mirror/stores/base_v2.module';
import axios from 'axios';
const moment = require('moment-timezone');

const config = require('../config.js')[ process.env.NODE_ENV ];
import { rwreport_service } from '@/services';

var options = {
    model_name    : 'rwreport',
    send_alert    : false,
    default_order : 'has_error ASC|created DESC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters       : {},
    amount_studio : 0,
    amount_users  : 0,
    pras_involved : [],
    erased        : false,
    selected_item : null,
    toast         : null,
    upload_active : false,
    alert_sent    : false,
    show_hidden   : false,
};

const getters = {
    ...base.getters,
}

const actions = {
    ...base.actions,
    override_item( { commit }, data ) { commit('override_item', data); },
    send_alert( { commit, getters }, rwreport_id ) {
        rwreport_service.send_alert(getters.model_name, rwreport_id)
            .then( response => {
                if ( response.sent === 'ok' ) {
                    commit('alert_sent');
                }
            });
    },
    get_all( { commit, state, getters, dispatch }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('reset_selected');
        commit('start_request');
        data.options.order = getters.order;
        Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
        data.options['ready__gt'] = 1;
        if ( state.items === null || state.force_reload === true || ( data.hasOwnProperty('force_reload') && data.force_reload === true ) ) {
            state.service.get_all(getters.model_name, data.options)
                .then( response => {
                    if ( data.options.paginate === true ) { // gestione paginator
                        commit('success_list', response.data);
                        commit('set_total', response.tot);
                        commit('set_page', response.page);
                        commit('set_rows_per_page', response.rows_per_page);
                    } else {
                        var list = JSON.parse( JSON.stringify( response ) );
                        //if ( state.show_hidden === false ) { // sbagliato!!! li nascondo in finestra
                        //    list = list.filter( x => x.data.hide !== true );
                        //}
                        for ( var i = 0; i < list.length; i++ ) {
                            if ( list[ i ].state === 'sent' ) { continue; }
                            if ( list[ i ].rwcompany.config.date_shift === 0 ) {
                                list[ i ].data.orazio.data_rassegna = moment().tz('Europe/Rome').format('YYYY-MM-DD');
                            } else {
                                var function_name = list[ i ].rwcompany.config.date_shift > 0 ? 'add' : 'subtract';
                                var delta = list[ i ].rwcompany.config.date_shift > 0 ? list[ i ].rwcompany.config.date_shift : list[ i ].rwcompany.config.date_shift * -1;
                                list[ i ].data.orazio.data_rassegna = moment().tz('Europe/Rome')[ function_name ](delta, 'days').format('YYYY-MM-DD');
                            }
                        }
                        commit('success_list', list);
                        if ( state.items && state.items.length > 0 ) {
                            dispatch( `set_selected`/*, state.items[0]*/ );
                        }
                    }
                })
                .catch( error => {
                    commit('error', error)
                });
        }
    },
    set_selected( { commit }, item = null ) {
        if ( item === null ) {
            // seleziono il primo (in linea con show_hidden)
            if ( state.show_hidden === true ) { // se li mostro tutti: il primo
                item = state.items[0];
            } else if ( state.show_hidden === false ) { // se mostro solo i visibili
                var found = false;
                var first_visible = null;
                for ( var i = 0; i < state.items.length; i++ ) {
                    if ( first_visible === null && state.items[ i ].data.hide !== true ) { first_visible = state.items[ i ]; }
                    if ( state.selected_item && state.selected_item.id === state.items[ i ].id ) { found = true; }
                    if ( !state.selected_item && first_visible ) { // se non ce n'è uno già selezionato: il primo visibile
                        item = first_visible;
                        break;
                    } else if ( state.selected_item ) { // se ce n'è uno già selezionato: il successivo visibile
                        if ( found && state.items[ i ].data.hide !== true ) {
                            item = state.items[ i ];
                            break;
                        }
                    }
                }
                // se non c'è un successivo ad uno già selezionato con show_hidden === false
                if ( !item && first_visible ) { item = first_visible; }
            }
        }
        commit( 'set_selected', item );
    },
    set_page( { commit, state, getters }, { item_id, page_index, value } ) {
        var pages = JSON.parse( JSON.stringify( state.map.get( item_id ).data.pages ) );
        pages[`page${ page_index }`] = value;
        axios.patch( config.backend_url + `model/${ getters.model_name }/${ item_id }/pages?include=true`, pages )
            .then( response => {
                commit( 'override_item', response.data );
            })
            .catch( error => {
                console.log( error ); // TODO gestire errore
            });
    },
    set_orazio_form( { commit, state, getters }, { item_id, payload } ) {
        axios.patch( config.backend_url + `model/${ getters.model_name }/${ item_id }/orazio?include=true`, payload )
            .then( response => {
                commit( 'override_item', response.data );
                commit( 'set_toast', { title: 'Aggiornamento dati Orazio', body: 'Dati salvati con successo' } );
            })
            .catch( error => {
                console.log( error ); // TODO gestire errore
            });
    },
    exclude( { commit, state, getters }, item_id ) {
        axios.patch( config.backend_url + `model/${ getters.model_name }/${ item_id }?include=true`, { has_error: true, error: 'Pagina esclusa manualmente' } )
            .then( response => {
                commit( 'override_item', response.data );
                commit( 'set_toast', { title: 'Aggiornamento rassegna', body: 'Pagina esclusa con successo' } );
            })
            .catch( error => {
                console.log( error ); // TODO gestire errore
            });
    },
    include( { commit, state, getters }, payload ) {
        axios.patch( config.backend_url + `model/${ getters.model_name }/${ payload.id }?include=true`, { has_error: false, data_rassegna: payload.data_rassegna } )
            .then( response => {
                // settare il nuovo selected: il successivo se in linea con show_hidden, altrimenti il primo
                commit( 'override_item', response.data );
                commit( 'set_toast', { title: 'Aggiornamento rassegna', body: 'Pagina inclusa con successo' } );
            })
            .catch( error => {
                console.log( error ); // TODO gestire errore
            });
    },
    set_hide( { commit, state, getters, dispatch }, payload ) {
        axios.patch( config.backend_url + `model/${ getters.model_name }/${ payload.id }/hide?include=true`, { hide: payload.hide } )
            .then( response => {
                commit( 'override_item', response.data );
                //commit( 'set_toast', { title: 'Aggiornamento rassegna', body: 'Pagina inclusa con successo' } );
                dispatch('set_selected', state.show_hidden === true ? state.selected_item : null);
                
                //commit( 'replace_list' );
            })
            .catch( error => {
                console.log( error ); // TODO gestire errore
            });
    },
    change_visibility( { state, commit, dispatch } ) {
        commit( 'set_params', { show_hidden: !state.show_hidden } );
        dispatch('set_selected');
    },
    replace_pdf({ commit, state, getters }, data) {
        //commit('start_request');
        commit( 'set_params', { upload_active : true } );
        rwreport_service.replace_pdf( state.selected_item.id, data.file )
            .then( response => {
                console.log( response );
                commit( 'override_item', response );
                commit( 'set_params', { upload_active : false } );
            })
            .catch( error => {
                commit( 'set_params', { upload_active : false } );
            });
    },
};

const mutations = {
    ...base.mutations,
//  replace_list( state ) {
//      // cerco l'item successivo se in linea con show_hidden
//      console.log( 'list.length', list.length );
//      for ( var i = 0; i < list.length; i++ ) {
//          if ( list[ i ].state === 'sent' ) { continue; }
//          if ( list[ i ].rwcompany.config.date_shift === 0 ) {
//              list[ i ].data.orazio.data_rassegna = moment().tz('Europe/Rome').format('YYYY-MM-DD');
//          } else {
//              var function_name = list[ i ].rwcompany.config.date_shift > 0 ? 'add' : 'subtract';
//              var delta = list[ i ].rwcompany.config.date_shift > 0 ? list[ i ].rwcompany.config.date_shift : list[ i ].rwcompany.config.date_shift * -1;
//              list[ i ].data.orazio.data_rassegna = moment().tz('Europe/Rome')[ function_name ](delta, 'days').format('YYYY-MM-DD');
//          }
//      }
//      commit('success_list', list);
//      if ( state.items && state.items.length > 0 ) {
//          dispatch( `set_selected`, state.items[0] );
//      }
//  },
    alert_sent( state ) {
        state.alert_sent = true;
        setTimeout( () => {
            state.alert_sent = false;
        }, 700 );
    },
    reset_selected( state ) { state.selected_item = null; },
    set_selected( state, item ) { state.selected_item = item; },
    success_list( state, items ) {
        state.items    = items;
        items.map( x => {
            state.map.set( x.id, x );
        });
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.executed = true;
    },
    override_item( state, item ) {
        var map = new Map();
        var items = [];
        for ( var i = 0; i < state.items.length; i++ ) {
            if ( state.items[ i ].id === item.id ) { items.push( item ); map.set( item.id, item ); continue; }
            items.push( state.items[ i ] );
            map.set( state.items[ i ].id, state.items[ i ] );
        }
        state.items = items;
        state.map   = map;
        if ( state.selected_item.id === item.id ) { state.selected_item = item; }
    },
    set_toast( state, obj ) { 
        state.toast = null;
        state.toast = obj;
    },
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};

