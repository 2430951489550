<script>
const nprogress = require('nprogress');
import company_selector from '@/extraction/company_selector';
import ankController from '@/extraction/ankController';
const { Howl, Howler } = require('howler');
const config       = require('@/config')[ process.env.NODE_ENV ];

export default {

    name: 'extraction-page',
    components: { 'company-selector': company_selector, ankController },
    props: [],
    mounted () {
        this.$nextTick(function () { nprogress.done(true); });
        this.$root.$on('bv::toast:hide', event => {
            if ( event.type === 'hide' ) {
                this.sprite_container.stop();
                this.play_in_progress = false;
                this.play_progress_value = 0;
                clearInterval( this.timer );
            }
        });
    },
    data() {
        return {
            step         : 60, // seconds
            parsing      : true,
            blocks       : [],
            changing     : false,
            main_key     : 0,
            complete     : true,
            selector_key : 0,
            sprites      : {},
            anchors      : new Map(),
            ank_key      : 0,
            actual_ank   : null,
            sprite_container    : null,
            play_progress_value : 0,
            play_in_progress    : false,
            modal_image_src     : null,
            modal_title         : null,
            modal_video_src     : null,
            timer               : null,
        }
    },
    watch: {
        store_loading( new_val, old_val ) {
            this.anchors = new Map();
            if ( new_val === false && old_val === true ) {
                var report_reg = null;
                this.parsing = true;
                this.sprite_container = null;
                this.sprites = {};
                if ( this.company_id === 0 ) {
                    report_reg = {};
                    this.complete = true;
                    var report = [];
                    var found_indexes = [];
                    var keywords      = []; // String( position ): [ ids ]
                    Object.keys( JSON.parse( JSON.stringify( this.obj.report ) ) ).map( company_id => {
                        this.obj.report[ company_id ].matches.map( _position => {
                            var position = _position;
                            keywords.push({ company_id, indexes: typeof position === 'number' ? [ position ] : position });
                        });
                    });
                    keywords.sort( (a,b) => {
                        return b.indexes.length - a.indexes.length;
                    });
                    Object.keys( JSON.parse( JSON.stringify( this.obj.report ) ) ).map( company_id => {
                        this.obj.report[ company_id ].matches.map( _position => {
                            var position = typeof _position === 'number' ? [ _position ] : _position;
                            for ( var i = 0; i < position.length; i++ ) {
                                var keyword_position = position[ i ];
                                if ( found_indexes.includes( keyword_position ) ) {
                                    report_reg[ String( keyword_position ) ].company_label += `, ${ this.obj.report[ company_id ].label }`;
                                    report_reg[ String( keyword_position ) ].companies.push( company_id );
                                    continue;
                                }
                                var position_obj = {
                                    index         : keyword_position,
                                    companies     : [ company_id ],
                                    company_label : this.obj.report[ company_id ].label,
                                };
                                report.push( keyword_position );
                                report_reg[ String( keyword_position ) ] = position_obj;
                                found_indexes.push( keyword_position )
                            }
                        });
                    });
                    report.sort( (a,b) => {
                        var first = typeof a === 'number' ? a : a[0];
                        var second = typeof b === 'number' ? b : b[0];
                        return first - second;
                    });
                } else if ( this.obj.report.hasOwnProperty( this.company_id ) === false || typeof this.obj.report[ this.company_id ] === 'undefined' ) {
                    this.complete = false;
                    return;
                } else {
                    this.complete = true;
                    var report = this.obj.report[ this.company_id ].matches;
                }
                var report_position = 0;
                var word_index = report[ report_position ];
                var words = [];
                var skip = 0;
                var temp_words = [];
                var ank_index = 0;
                for ( var i = 0; i < this.obj.json.length; i++ ) {
                    var x = Object.assign( {}, this.obj.json[ i ] );
                    if ( skip > 0 ) {
                        temp_words.push( x.Word );
                        skip -= 1;
                        if ( skip === 0 ) {
                            var sprite_start    = x.startTime < 5 ? 0 : ( x.startTime * 1000 ) - 5000;
                            var sprite_duration = ( ( x.endTime - x.startTime ) * 1000 ) + 10000;
                            var sprite_index = JSON.stringify( [ sprite_start, sprite_duration ] );
                            this.sprites[ sprite_index ] = [ sprite_start, sprite_duration ];
                            var obj = {
                                text      : temp_words.join(' '),
                                time      : this.time_label( x.endTime ),
                                ank_index,
                                sprite_index,
                            };
                            if ( report_reg !== null && report_reg.hasOwnProperty( String( report[ report_position ] ) ) ) {
                                obj.company_label = report_reg[ String( report[ report_position ] ) ].company_label;
                            }
                            x.Word = obj;
                            words.push( x );
                            temp_words = [];
                            this.anchors.set( ank_index + 0, { index: ank_index, ank: `ank${ ank_index }` } );
                            ank_index += 1;
                        }
                        continue;
                    }
                    if ( Array.isArray( word_index ) === false && i === word_index ) {
                        var sprite_start    = x.startTime < 5 ? 0 : ( x.startTime * 1000 ) - 5000;
                        var sprite_duration = ( ( x.endTime - x.startTime ) * 1000 ) + 10000;
                        var sprite_index = JSON.stringify( [ sprite_start, sprite_duration ] );
                        this.sprites[ sprite_index ] = [ sprite_start, sprite_duration ];
                        var obj = {
                            text      : x.Word,
                            time      : this.time_label( x.endTime ),
                            ank_index : ank_index,
                            sprite_index,
                        };
                        if ( report_reg !== null && report_reg.hasOwnProperty( String( word_index ) ) ) {
                            obj.company_label = report_reg[ String( word_index ) ].company_label;
                        }
                        x.Word = obj;
                        report_position += 1;
                        word_index = report[ report_position ];
                        words.push( x );
                        this.anchors.set( ank_index + 0, { index: ank_index, ank: `ank${ ank_index }` } );
                        ank_index += 1;
                    } else if ( Array.isArray( word_index ) && i === word_index[0] ) {
                        skip = word_index.length - 1;
                        report_position += 1;
                        word_index = report[ report_position ];
                        temp_words.push( x.Word );
                    } else {
                        words.push( x );
                    }
                }
                if ( report_reg ) {}
                var block = { min: 0, max: this.step, words: [] };
                var total_words = words.length;
                var counter = 0;
                var anks = [];
                words.map( x => {
                    counter += 1;
                    if ( x.endTime < block.max + 0.1 ) {
                        block.words.push( x.Word );
                        if ( typeof x.Word === 'object' ) { anks.push( x.Word.ank_index ); }
                    } else if ( x.endTime > block.max + 0.1 ) {
                        this.sprites[ 'block' + String( this.blocks.length + 1 ) ] = [ block.min * 1000, 60000 ];
                        this.blocks.push( Object.assign( {}, block ) );
                        anks.map( ank_index => {
                            this.anchors.get( ank_index ).block = this.blocks.length - 1;
                        });
                        anks = [];
                        block.min += this.step;
                        block.max += this.step;
                        block.words = [ x.Word ];
                        if ( typeof x.Word === 'object' ) { anks.push( x.Word.ank_index ); }
                    }
                    if ( counter === total_words && block.words.length > 0 ) {
                        this.sprites[ 'block' + String( this.blocks.length + 1 ) ] = [ block.min * 1000, 60000 ];
                        block.max = x.endTime;
                        this.blocks.push( Object.assign( {}, block ) );
                        anks.map( ank_index => {
                            this.anchors.get( ank_index ).block = this.blocks.length - 1;
                        });
                    }
                });
                if ( this.obj.audiopath.startsWith('http') ) { // da rimuovere dopo il 05/03/2021
                    var audio_path = this.obj.audiopath;
                } else {
                   var audio_path = config.backend_url + this.obj.audiopath.replace(/^\./, '' ); 
                }
                if ( this.has_audio ) {
                    this.sprite_container = new Howl({
                        src    : [ audio_path ],
                        sprite : this.sprites,
                        html5  : true,
                    });
                    var self = this;
                    this.sprite_container.on('end', function(){
                        setTimeout( () => {
                            self.$bvToast.hide('play-toast');
                            self.play_in_progress = false;
                        }, 1500);
                    });
                }
                this.parsing = false;
                this.main_key += 1;
            }
            console.log( this.anchors.size );
            this.ank_key += 1;
        },
    },
    created() {
        this.selector_key += 1;
        this.$store.dispatch( 'extraction/get_by_id', { id: this.$route.params.id, include: true } );
    },
    computed: {
        has_audio() { return this.obj.audiopath !== null; },
        store_loading() { return this.company_id === null ? true : this.$store.state.extraction.loading_by_id },
        loading() {
            if ( this.changing === true ) {
                return true;
            } else if ( this.obj.hasOwnProperty('id') === false || this.store_loading === true ) {
                return true;
            } else {
                return this.parsing;
            }
        },
        obj() { return this.$store.state.extraction.obj; },
        company_id: {
            get() { return this.$store.state.extraction.company_id; },
            set( value ) { 
                this.$store.dispatch('extraction/set_params', { company_id: value });
            },
        },
    },
    methods: {
        start_timer( index ) {
          var self = this;
          var reached = false;
          this.timer = setInterval( function() {
            if ( reached === true ) { var perc = 100; }
            else { var perc = ( ( self.sprite_container.seek() * 1000 - self.sprites[ index ][0] ) * 100 ) / self.sprites[ index ][1] };
            if ( reached === false && perc > 95 ) {
                reached = true;
                setTimeout( () => clearInterval(this.timer), 1000 )
            }
            self.play_progress_value = perc;
          }, 100);
        },
        play_sprite( index ) {
            if ( this.has_audio === false || this.play_in_progress === true || this.sprite_container === null ) { return; }
            this.play_progress_value = 0;
            this.$bvToast.show('play-toast');
            this.start_timer( index );
            this.play_in_progress = true;
            this.sprite_container.play( index );
        },
        includes_key( report, item ) {
            var check = false;
            for ( var i = 0; i < report.length; i++ ) {
                var element = report[ i ];
                if ( Array.isArray( element ) ) {
                    if ( JSON.stringify( element ) === JSON.stringify( item ) ) { return i; }
                } else {
                    if ( element === item ) { return i; }
                }
            }
            return check;
        },
        change_company( value ) {
            if ( this._company_id === value ) { return; }
            this.changing = true;
            this.company_id = null;
            this.blocks = [];
            setTimeout( () => {
                this.company_id = value;
                this.changing = false;
            }, 300);
        },
        time_label( seconds ) {
            var sec = Math.trunc( seconds );
            var date_string = new Date( sec * 1000 ).toISOString().substr(11, 8);
            if ( date_string === '00:00:00' ) { return '0\''; }
            var tokens = date_string.split(':');
            var tokens_res = [];
            if ( tokens[0] !== '00' ) { tokens_res.push( String( parseInt( tokens[0] ) ) + 'h' ); }
            if ( tokens[1] !== '00' ) { tokens_res.push( String( parseInt( tokens[1] ) ) + '\'' ); }
            if ( tokens[2] !== '00' ) { tokens_res.push( String( parseInt( tokens[2] ) ) + '\'\'' ); }
            return tokens_res.join(' ');
        },
        back_to_list() {
            this.$router.push( '/list/extraction' );
        },
        get_title( wordobj ) {
            if ( wordobj.hasOwnProperty('company_label') ) { return `${ wordobj.time } - ${ wordobj.company_label }`; }
            return wordobj.time;
        },
        open_image( image_number ) {
            if ( !this.obj.config.thumbs[ image_number ] ) { return }
            this.modal_image_src = this.obj.config.thumbs[ image_number ];
            this.modal_title = new Date((image_number + 1 ) * 10000).toISOString().substr(11, 8);
            console.log( this.modal_title );
            this.$bvModal.show('modal-image');
        },
        play_video( block_index ) {
            this.modal_video_src = this.obj.videopath + `#t=${ block_index * 60 }`;
            this.$bvModal.show('modal-video');
        },
        go_to_anchor( ank ) {
            this.actual_ank = ank;
            var element = this.$refs[ 'block' + String( this.anchors.get( this.actual_ank ).block ) ][0];
            var top = element.offsetTop;
            window.scrollTo(0, top);
        },
        get_span_style( ank_index ) {
            if ( ank_index === this.actual_ank ) {
                return { 'background-color' : 'blue', 'color': 'white' };
            }
            return {
                'background-color' : this.has_audio ? '#FF5722' : 'yellow',
                'color' : this.has_audio ? 'white' : 'black',
            };
        },
    },

}

</script>

<template>

    <div style="width: 80%; margin: auto; padding-bottom: 70px;">
        <div>
            <h3 class="text-center" style="margin-bottom: 36px;">Testo estratto da<br/><b-badge variant="info">{{ this.obj.file_name }}</b-badge></h3>
            <b-row style="margin-bottom: 40px">
                <b-col>
                    <b-button @click="back_to_list" size="sm" variant="outline-dark"><icon name="arrow-left"/> Torna a lista</b-button>
                </b-col>
                <b-col align-self="end" >
                    <company-selector
                        v-if              = "obj.report"
                        :key              = "selector_key"
                        :add_empty_option = "true"
                        :select_first     = "true"
                        :value            = "company_id"
                        @change           = "change_company"
                        :report           = "obj.report"
                        >
                    </company-selector>
                </b-col>
            </b-row>
            <b-row v-if="!loading && complete === true">
                <b-col :key="main_key">
                    <template v-for="( block, block_index ) in blocks">
                        <b-list-group-item :ref="'block' + String( block_index )">
                            <b-row align-h="between">
                                <b-col cols="1">
                                    <b-badge variant="secondary">{{ time_label( block.min ) }} &rarr; {{ time_label( block.max ) }}</b-badge>
                                </b-col>
                                <b-col cols="1">
                                    <b-button-toolbar>
                                        <b-button-group class="mr-1">
                                            <b-button size="sm" @click="play_sprite( 'block' + String( block_index + 1 ) )"><icon style="cursor: pointer;" name="music"/></b-button>
                                            <b-button size="sm" :disabled="obj.config.hasOwnProperty('preview_available') && obj.config.preview_available === false ? true : false" @click="play_video( block_index )"><icon name="video"/></b-button>
                                        </b-button-group>
                                    </b-button-toolbar>
                                </b-col>
                            </b-row>
                            <!-- <span v-html="block.words.join(' ')"></span> -->
                            <template v-for="(word, index ) in block.words">
                                <span v-if="typeof word === 'string'">{{ word + ' ' }}</span>
                                <span v-else-if="typeof word === 'object'" :style="{ 'cursor': has_audio ? 'pointer' : 'default' }" @click="play_sprite( word.sprite_index )">
                                        <!-- :style="{ 'background-color': has_audio ? '#FF5722' : 'yellow', 'color': has_audio ? 'white' : 'black' }" -->
                                        <!-- :style="{ 'background-color': word.ank_index === actual_ank ? 'yellow' : '#FF5722', 'color': has_audio ? 'white' : 'black' }" -->
                                    <span
                                        :style="get_span_style( word.ank_index )"
                                        style="padding-left: 2px; padding-right: 2px;"
                                        :title="get_title( word )"
                                        :ref="'ank' + String( actual_ank )"
                                        v-b-tooltip.hover
                                        >
                                        {{ word.text }}{{ ( block.words[ index + 1 ] && typeof block.words[ index + 1 ] === 'object' ) ? ' ' : '' }}
                                    </span>{{ ( block.words[ index + 1 ] && typeof block.words[ index + 1 ] === 'object' ) ? '' : ' ' }}</span> 
                            </template>
                            <b-container fluid class="p-3" style="margin-top: 10px; padding: 3px;" v-if="obj.config && obj.config.thumbs">
                                <b-row style="">
                                    <b-col style="padding: 4px;" :style="{ cursor: obj.config.thumbs[ block_index * 6 ] ? 'pointer' : 'default' }" @click="open_image( block_index * 6 )">
                                      <b-img-lazy style="border: 2px solid grey;" thumbnail v-if="obj.config.thumbs[ block_index * 6 ]" :src="obj.config.thumbs[ block_index * 6 ]" fluid :alt="obj.file_name +  '- image ' + ( block_index * 6 )"></b-img-lazy>
                                    </b-col>
                                    <b-col style="padding: 4px;" :style="{ cursor: obj.config.thumbs[ ( block_index * 6 ) + 1 ] ? 'pointer' : 'default' }" @click="open_image( ( block_index * 6 ) + 1 )">
                                      <b-img-lazy style="border: 2px solid grey;" thumbnail v-if="obj.config.thumbs[ (block_index * 6 ) + 1 ]" :src="obj.config.thumbs[ (block_index * 6 ) + 1 ]" fluid :alt="obj.file_name +  '- image ' + ( block_index * 6 )"></b-img-lazy>
                                    </b-col>
                                    <b-col style="padding: 4px;" :style="{ cursor: obj.config.thumbs[ ( block_index * 6 ) + 2 ] ? 'pointer' : 'default' }" @click="open_image( ( block_index * 6 ) + 2)">
                                      <b-img-lazy style="border: 2px solid grey;" thumbnail v-if="obj.config.thumbs[ (block_index * 6 ) + 2 ]" :src="obj.config.thumbs[ (block_index * 6 ) + 2 ]" fluid :alt="obj.file_name +  '- image ' + ( block_index * 6 )"></b-img-lazy>
                                    </b-col>
                                <!-- </b-row>
                                <b-row style=""> -->
                                    <b-col style="padding: 4px;" :style="{ cursor: obj.config.thumbs[ ( block_index * 6 ) + 3 ] ? 'pointer' : 'default' }" @click="open_image( ( block_index * 6 ) + 3 )">
                                      <b-img-lazy style="border: 2px solid grey;" thumbnail v-if="obj.config.thumbs[ (block_index * 6 ) + 3 ]" :src="obj.config.thumbs[ (block_index * 6 ) + 3 ]" fluid :alt="obj.file_name +  '- image ' + ( block_index * 6 )"></b-img-lazy>
                                    </b-col>
                                    <b-col style="padding: 4px;" :style="{ cursor: obj.config.thumbs[ ( block_index * 6 ) + 4 ] ? 'pointer' : 'default' }" @click="open_image( ( block_index * 6 ) + 4 )">
                                      <b-img-lazy style="border: 2px solid grey;" thumbnail v-if="obj.config.thumbs[ (block_index * 6 ) + 4 ]" :src="obj.config.thumbs[ (block_index * 6 ) + 4 ]" fluid :alt="obj.file_name +  '- image ' + ( block_index * 6 )"></b-img-lazy>
                                    </b-col>
                                    <b-col style="padding: 4px;" :style="{ cursor: obj.config.thumbs[ ( block_index * 6 ) + 5 ] ? 'pointer' : 'default' }" @click="open_image( ( block_index * 6 ) + 5 )">
                                      <b-img-lazy style="border: 2px solid grey;" thumbnail v-if="obj.config.thumbs[ (block_index * 6 ) + 5 ]" :src="obj.config.thumbs[ (block_index * 6 ) + 5 ]" fluid :alt="obj.file_name +  '- image ' + ( block_index * 6 )"></b-img-lazy>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-list-group-item>
                    </template>
                </b-col>
            </b-row>
        </div>
        <div v-if="loading && complete === false">
            <div class="text-center">
                <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div v-if="!loading && complete === false" class="text-center" style="margin-top: 50px;">
            <b-icon-info-circle class="h1 mb-2" style="color: grey"></b-icon-info-circle><br/>
            <p class="h5 mb-2" style="color: grey; margin-top: 20px">
                Il file non è ancora stato elaborato.<br/><br/>
                Torna alla lista per aprirne un altro<br/>
                o per verificarne lo stato.
            </p>
        </div>

        <ank-controller
            v-if          = "anchors.size > 0"
            :anchors      = "anchors"
            :key          = "ank_key"
            @go-to-anchor = "go_to_anchor"
        />

        <b-modal id="modal-image" size="lg" :hide-footer="true" :title="'Screenshot all\'istante ' + modal_title">
            <div style="margin-bottom: 20px; " class="text-center">
                <b-img-lazy :src="modal_image_src" fluid alt=""></b-img-lazy>
            </div>
        </b-modal>

        <b-modal id="modal-video" size="lg" :hide-footer="true" :title="obj.file_name">
            <div style="margin-bottom: 20px; " class="text-center">
                <video id="vid1" width="100%" height="auto" autoplay="true" controls>
                    <source :src="modal_video_src" type="video/mp4" /> 
                    Your browser does not support the video tag.
                </video>
            </div>
        </b-modal>

        <b-toast
            id       = "play-toast"
            variant  = "dark"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            solid
            no-auto-hide
            >
            <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-baseline">
                    <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
                    <strong class="mr-auto">Riproduzione in corso...</strong>
                </div>
            </template>
            <b-progress height="6px" :value="play_progress_value" :max="100"></b-progress>
            <!-- <small v-if="file_name" style="color: grey;">{{ file_name | truncate(55, '...') }}<b-badge variant="light" style="float: right; margin-top: 4px">{{ Math.round(file_percentage) }}%</b-badge></small>
            <b-progress style="margin-top: 10px;" height="3px" :value="queue_percentage" :max="100"></b-progress>
            <small style="">Progresso Totale<b-badge variant="light" style="float: right; margin-top: 4px;">{{ queue_percentage }}%</b-badge></small> -->
        </b-toast>

    </div>

</template>

