<script>

const moment  = require('moment')
const schemas = require('@/schemaconfig');
import bnvform from '@/vue-model-mirror/bnvform';
import rwstatus from './status';

export default {
    name: 'rw-toolbar',
    components: {
        bnvform,
        rwstatus,
    },
    mounted () {
        this.load_assignment();
    },
    watch: {
        loading( new_value, old_value ) {
            if ( new_value === false && old_value === true ) {
                this.emit();
                this.loaded = true;
            }
        },
        loading_assignments( new_value, old_value ) {
            if ( new_value === false && old_value === true ) {
                if ( this.loading_rwqueue_item === false ) {
                    this.$root.$emit('bv::toggle::modal', 'modal-status');
                }
            }
        },
        loading_rwqueue_item( new_value, old_value ) {
            if ( new_value === false && old_value === true ) {
                    console.log( this.rwqueue_items.length );
                if ( this.loading_assignments === false ) {
                    this.$root.$emit('bv::toggle::modal', 'modal-status');
                }
            }
        },
        reset_assignment( new_value, old_value ) {
            if ( new_value === true && old_value === false ) {
                this.emit();
            }
        },
        rama_loading( new_val, old_val ) {
            if ( new_val === true ) { this.$bvToast.show('rama-toast'); }
            else { this.$bvToast.hide('rama-toast'); }
        },
    },
    computed: {
        store() { return this.$store.state[ this.schema_name ] },
        user_id() { return JSON.parse( localStorage.getItem('user') ).id; },
        loading() { return this.store.loading; },
        loading_assignments() { return this.$store.state.rwassignment_all.loading; },
        loading_rwqueue_item() { return this.$store.state.rwqueue_item.loading; },
        assignment() { return this.store.items && this.store.items.length > 0 ? this.store.items[0] : null },
        is_mine() {
            if ( this.assignment ) {
                return this.assignment.user_id === this.user_id;
            }
            return false;
        },
        is_assigned() {
            if ( this.assignment ) {
                return this.assignment.user_id !== null;
            }
            return true;
        },
        companies() { return this.$store.state.rwcompany.items },
        options() {
            var options = this.companies.map( x => { return { value: x.id, text: x.label }; } );
            return options;
        },
        filter_date: {
            get() {
                if ( this.date === null ) {
                    this.date = moment().subtract( 1, 'days' ).format('YYYY-MM-DD');
                }
                return this.date;
            },
            set( value ) {
                this.date = value;
            }
        },
        filter_company: {
            get() {
                if ( this.company === null && this.options.length > 0  ) {
                    this.company = this.options[0].value;
                }
                return this.company;
            },
            set( value ) {
                this.company = value;
            }
        },
        date: {
            get() { return this.store.date; },
            set( value ) {
                this.$store.dispatch( 'rwassignment/set_params', { date: value } );
                this.load_assignment();
            }
        },
        company: {
            get() { return this.store.company; },
            set( value ) {
                this.$store.dispatch( 'rwassignment/set_params', { company: value } );
                this.load_assignment();
            }
        },
        reset_assignment() { return this.store.reset_assignment; },
        assignments() { return this.$store.state.rwassignment_all.items || [] },
        rwqueue_items() { return this.$store.state.rwqueue_item.items || [] },
        rama_loading() { return this.$store.state.websocket_logger.rama_loader; },
        rama_message() { return this.$store.state.websocket_logger.message_rama || {}; },
        show_hidden() { return this.$store.state.rwreport.show_hidden; },
    },
    methods: {
        take_in_charge() {
            this.$store.dispatch( `${ this.schema_name }/update`, { id: this.assignment.id, payload: { user_id: this.user_id, state: 'assigned' } } );
        },
        give_up() {
            this.$store.dispatch( `${ this.schema_name }/update`, { id: this.assignment.id, payload: { user_id: null, state: 'unassigned' } } );
        },
        emit() {
            this.$emit( 'update-values', { company: this.company, date: this.date, read_only: !this.is_mine });
        },
        load_assignment() {
            this.$store.dispatch(`${ this.schema_name }/get_all`, { options: { date: this.filter_date, rwcompany_id: this.filter_company }, force_reload: true })
        },
        queue_customer() {
            var items = this.$store.state.rwreport.items;
            if ( !items || items.length === 0 ) { return; }
            var ok_items = items.filter( x => x.has_error === false && x.state === null && x.data.locked === false );
            if ( ok_items.length === 0 ) {
                this.$bvModal.msgBoxOk('Non sono state trovate pagine da inviare per il customer in visione.')
                    .then( value => {} )
                    .catch( error => {
                        console.log( error );
                    });
                return;
            }
            this.$bvModal.msgBoxConfirm(`Aggiungere ${ ok_items.length } pagine alla coda di invio?`)
                .then( value => {
                    if ( value === true ) {
                        this.$store.dispatch( `${ this.schema_name }/queue_customer`, {
                            reports : ok_items.map( x => { return { id: x.id, date: x.date, company_id: x.rwcompany_id }; } ),
                            company : this.company,
                            date    : this.filter_date,
                        });
                    }
                })
                .catch( error => {
                    console.log( error );
                });
        },
        show_status() {
            this.$store.dispatch('rwassignment_all/get_all', { options: { date: this.filter_date, include: true }, force_reload: true })
            this.$store.dispatch('rwqueue_item/get_all', { options: { date: this.filter_date, include: true }, force_reload: true })
        },
        toggle_modal( modal_id ) {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        rassegna_manuale() {
            this.toggle_modal('modal-rama');
        },
        operation_success( payload ) {
            console.log( payload );
            this.$store.dispatch('websocket_logger/set_path_rama', { wspath: 'ws/rama', payload })
            this.toggle_modal('modal-rama');
        },
        submit_rama_form() {
            this.$refs.rama_form.submit_form();
        },
        set_visibility() {
            this.$store.dispatch( 'rwreport/change_visibility' );
        },
    },
    created() {
        setTimeout( () => {
            //
        }, 500);
    },
    data() {
        return {
            schema_name    : 'rwassignment',
            loaded         : false,
            modal_rama_key : 0,
            schema         : schemas.rassegna_manuale,
        };
    },
};

</script>

<template>

    <div>

        <b-row v-if="loaded" align-h="between" align-v="center">
    
            <!-- state -->
            <b-button :disabled="!assignment || loading_assignments" variant="info" @click="show_status" v-b-tooltip.hover="{ title: 'Pannello di controllo', placement: 'bottomright', variant: 'dark' }"><icon name="question"/></b-button>
    
            <!-- rassegna manuale -->
            <b-button variant="warning" @click="rassegna_manuale"v-b-tooltip.hover="{ title: 'Rassegna Manuale', placement: 'bottomright', variant: 'dark' }"><icon name="bookmark"/></b-button>

            <!-- mostra/nascondi esclusi -->
            <b-button variant="dark" @click="set_visibility" v-b-tooltip.hover="{ title: show_hidden === true ? 'Nascondi esclusi' : 'Mostra tutti', placement: 'bottomright', variant: 'dark' }"><icon :name="show_hidden === true ? 'eye' : 'eye-slash'"/></b-button>
            <!-- date -->
            <div style="margin-top: 8px;">
                <b-form-datepicker id="example-datepicker" v-model="filter_date" class="mb-2"></b-form-datepicker>
            </div>
    
            <!-- customer -->
            <div>
                <b-form-select v-model="filter_company" :options="options"></b-form-select>
            </div>
    
            <!-- action -->
            <b-button v-if="assignment && !is_mine && assignment.state === 'sending'" :disabled="true" variant="warning" >Accodato per l'invio</b-button>
            <b-button v-else-if="!assignment || !is_mine" :disabled="is_assigned" variant="info" @click="take_in_charge">Prendi in carico</b-button>
            <b-button v-else-if="is_mine" variant="warning" @click="give_up">Abbandona</b-button>
    
            <!-- action 2 (invia) -->
            <b-button :disabled="!is_mine" variant="dark" @click="queue_customer"><icon class="icon_legend" name="arrow-right"/>Invia tutto</b-button>
    
        </b-row>

        <!-- modal status -->
        <b-modal
            id          = "modal-status"
            title       = "Stato Rassegna"
            :ok-only    = "true"
            button-size = "sm"
            scrollable
            >
            <rwstatus 
                :assignments = "assignments"
                :reports     = "rwqueue_items"
                :companies   = "companies"
            />
        </b-modal>

        <!-- modal rassegna manuale -->
        <b-modal
            id="modal-rama"
            title="Rassegna Manuale"
            button-size="sm"
            >
            <bnvform
                :key       = "modal_rama_key"
                name       = "rassegna_manuale"
                :schema    = "schema"
                :options   = "{}"
                :record    = "{}"
                :submit    = "false"
                :inline    = "true"
                ref        = "rama_form"
                layout     = "single"
                @submitted = "operation_success"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_rama_form">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- rama toast -->
        <b-toast
            no-auto-hide
            id       = "rama-toast"
            :title   = "rama_message.class"
            :variant = "rama_message.variant || 'info'"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            >
			<span>{{ rama_message.action }}</span><b-spinner v-if="rama_message.stop !== true" :variant="rama_message.variant" label="Spinning" style="float: right;" small></b-spinner>
        </b-toast>

    </div>

</template>

<style>
</style>

